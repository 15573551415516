<template>
  <article-page :menu="menu">
    <div class="training-content" id="section-comments">
      <div class="_subtitle">
        Блок комментариев
      </div>
      <span>
        Пользователи с правами на запись могут оставлять комментарии к каждой закупке.
        В таблице с закупками на любом этапе бизнес-процесса доступно соответствующее поле.
        Свои комментарии можно добавлять в избранное или удалять.
        Пользователь с правами Администратора может добавлять в избранное и удалять чужие комментарии.
      </span>
      <article-image :src="images[0].src" :alt="images[0].alt" @click="openViewer(images, 0)"/>
      <span>
        Комментарии к закупкам можно оставлять в 5 разделах. Комментарии в 4 разделах объединены (при написании комментариев в этих разделах, их будет так же видно и в других разделах):
      </span>
      <ul>
        <li>Режим быстрого поиска;</li>
        <li>Режим поиска по шаблонам;</li>
        <li>Вкладка "Заявки";</li>
        <li>Подробная карта лота;</li>
      </ul>
      <span>
        Пятый раздел - вкладка "Контракты". Комментарии из этого раздела не видно в соответствующих карточках лотов в других разделах:
      </span>
      <ul>
        <li>Вкладка "Контракты" (в том числе все подвкладки);</li>
      </ul>
      <alert-message type="success" full-info>
        <template #content>
          Краткая справка: комментарии, написанные в любом разделе, <b>исключая раздел "контракты"</b>, видны в карточках лотов на остальных вкладках.
          Комментарии, написанные в карточке контракта, видны <b>только</b> в разделе "контракты".
          Если из карточки контракта перейти в подробную карту лота, то Вы увидите комментарии из остальных разделов.
          Соответственно при написании комментария в подробной карте лота, его не будет видно в карточке соответствующего контракта.
        </template>
      </alert-message>
    </div>
  </article-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useImageViewer } from "@/use/other/useImageViewer";
import ArticlePage from "@/components/pages/training/ArticlePage.vue";
import ArticleImage from "@/components/pages/training/ArticleImage.vue";
import AlertMessage from "~/components/ui/alert/AlertMessage.vue";

export default defineComponent({
  name: "CommentsArticle",
  components: {
    AlertMessage,
    ArticlePage,
    ArticleImage,
  },
  setup() {

    const menu = [
      { id: 'section-comments', title: 'Блок комментариев' },
    ]

    const images = [
      { src: 'base/create-comment.png', alt: 'Комментирование закупки' },
    ]

    const { openViewer } = useImageViewer();

    return {
      menu,
      images,
      openViewer,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/pages/training';
</style>
